import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'dateHM',
})
export class DateHMPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private localId: string) {}
  transform(value: string | number | Date): unknown {
    try {
      return formatDate(value, 'dd.MM.yyyy HH:mm', this.localId);
    } catch (e) {
      return null;
    }
  }
}
