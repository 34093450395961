import { Inject, LOCALE_ID, Pipe, type PipeTransform } from '@angular/core';
import { DatetimeService } from '../services/datetime.service';
import { Timestamp } from '../interfaces/DateTime';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'timestampFormat',
})
export class TimestampPipe implements PipeTransform {
  private defaultFormat: string = 'dd.MM.yyyy HH:mm';
  constructor(
    @Inject(LOCALE_ID) private localId: string,
    private dateTimeService: DatetimeService
  ) {}

  transform(value: Timestamp, format: string = this.defaultFormat): string {
    if (!value) return '';
    return formatDate(
      this.dateTimeService.convertTimestamp(value),
      format,
      this.localId
    );
  }
}
