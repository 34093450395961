import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { DateHMPipe } from './date-hm.pipe';
import { TimestampPipe } from './timestamp.pipe';

@NgModule({
  declarations: [DateHMPipe, TimestampPipe],
  imports: [CommonModule],
  providers: [DecimalPipe, DateHMPipe, TimestampPipe],
  exports: [DecimalPipe, DateHMPipe, TimestampPipe],
})
export class PipesModule {}
