import { Injectable } from '@angular/core';
import { Timestamp } from '../interfaces/DateTime';
import moment, { Moment } from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DatetimeService {
  constructor() {}

  convertTimestamp(timestamp: Timestamp): Date {
    const secondsInMillis = timestamp.seconds * 1000;
    const nanosInMillis = timestamp.nanos / 1e6;
    return new Date(secondsInMillis + nanosInMillis);
  }

  convertDateToTimestamp(date: Date | null): Timestamp | null {
    if (!date) {
      return null; // Return null if the date is null
    }

    const seconds = Math.floor(date.getTime() / 1000); // Convert milliseconds to seconds
    const nanos = (date.getTime() % 1000) * 1e6; // Convert remaining milliseconds to nanoseconds
    return {
      seconds: seconds,
      nanos: nanos,
    };
  }

  convertTimestampToMoment(timestamp: Timestamp): Moment {
    const secondsInMillis = timestamp.seconds * 1000;
    const nanosInMillis = timestamp.nanos / 1e6;
    return moment(secondsInMillis + nanosInMillis);
  }

  getMiliseconds(timestamp: Timestamp): number {
    const secondsInMillis = timestamp.seconds * 1000;
    const nanosInMillis = timestamp.nanos / 1e6;
    return secondsInMillis + nanosInMillis;
  }

  getDuration(duration: Timestamp): string {
    const durationInMillis = duration.seconds * 1000 + duration.nanos / 1e6;
    const now = new Date().getTime();
    const startTime = now - durationInMillis;

    const differenceInMillis = now - startTime;

    // Calculate the difference in terms of hours, minutes, and seconds
    const hours = Math.floor(differenceInMillis / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMillis % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((differenceInMillis % (1000 * 60)) / 1000);

    return `${hours} hours, ${minutes} minutes, ${seconds} seconds ago`;
  }
}
